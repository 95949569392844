import { theme } from "@upsolve/ui";
import React, { useState } from "react";
import styled from "styled-components";
import ThumbsUp from "./images/thumbs-up.svg";
import ThumbsDown from "./images/thumbs-down.svg";
import QuestionMark from "./QuestionMark.svg";
import { TOptionInfo } from "./ResultsComparison";
import { formatMonths } from "./formatMonths";
import { DetailHeading, DetailDescriptionCentered, AIPromptButton, Button } from "./Components";
import { addMonths, format } from "date-fns";
import { PROMPTS } from "./constants";
import {
  getDischargeableClaims,
  getNonDischargeableClaims,
  MeridianLinkCreditReportClaim,
} from "./meridianLinkCreditReport/meridianLinkCreditReportUtils";
import { ModalClose } from "../modals/Modal";
import { getReadableDebtType } from "./CreditPullScreen";

type TStatusQuoInfo = {
  interest: number;
  totalPayment: number;
  monthlyPayment: number;
  monthsInRepayment: number;
  canAfford: boolean;
};

export const DetailCard = (props: {
  optionInfo: TOptionInfo;
  unsecuredDebtEstimate: number;
  availableMonthlyFunds: number;
  statusQuoInfo: TStatusQuoInfo;
  claims: MeridianLinkCreditReportClaim[];
  onClickPromptButton: (messageText: string) => void;
  onClickBack: () => void;
  onOpenChat: () => void;
  trackImpression: (subcomponentName: string) => void;
}) => {
  // TODO: track views
  const showItemizedDebts = !!props.claims.length;
  const dischargeableDebts = getDischargeableClaims(props.claims);
  const nondischargeableDebts = getNonDischargeableClaims(props.claims);

  const [isItemizedDebtsViewOpen, setIsItemizedDebtsViewOpen] = useState(false);
  const [isIneligibleAccountsSectionOpen, setIsInEligibleAccountsSectionOpen] = useState(false);
  const disclaimerText =
    props.optionInfo.optionName === "Ch. 7 Bankruptcy"
      ? "*Eligible accounts are an estimate of which debts may be discharged in bankruptcy. You should speak to an attorney to determine which debts can be discharged."
      : "*Eligible accounts are an estimate of which debts may be discharged using this debt relief plan.";

  if (isItemizedDebtsViewOpen) {
    return (
      <ItemizedDebtsView>
        <ModalClose onClick={() => setIsItemizedDebtsViewOpen(false)} />
        <ModalHeader>
          <ModalTitle>Eligible accounts</ModalTitle>
        </ModalHeader>
        <div>
          <AccountList>
            {dischargeableDebts.map((claim) => {
              const claimName = claim.creditor?.name || claim.subType || claim.type;
              const debtType = getReadableDebtType(claim.subType || claim.type || "");
              return (
                <AccountItem key={claim.id}>
                  <div className="account-info">
                    <AccountName>{claimName}</AccountName>
                    <AccountType>{debtType}</AccountType>
                  </div>
                  <AccountBalance>${(claim.value || "0").toLocaleString()}</AccountBalance>
                </AccountItem>
              );
            })}
          </AccountList>
          <Disclaimer>{disclaimerText}</Disclaimer>
        </div>
        {isIneligibleAccountsSectionOpen && (
          <>
            <ModalHeader>
              <ModalTitle>Ineligible accounts</ModalTitle>
            </ModalHeader>
            <AccountList>
              {nondischargeableDebts.map((claim) => {
                const claimName = claim.creditor?.name || claim.subType || claim.type;
                const debtType = getReadableDebtType(claim.subType || claim.type || "");
                return (
                  <AccountItem key={claim.id}>
                    <div className="account-info">
                      <AccountName>{claimName}</AccountName>
                      <AccountType>{debtType}</AccountType>
                    </div>
                    <AccountBalance>${(claim.value || "0").toLocaleString()}</AccountBalance>
                  </AccountItem>
                );
              })}
            </AccountList>
          </>
        )}
        <div>
          <Button secondary onClick={() => setIsInEligibleAccountsSectionOpen(!isIneligibleAccountsSectionOpen)}>
            Other accounts&nbsp;
            <ChevronDownIcon rotate={isIneligibleAccountsSectionOpen} />
          </Button>
        </div>
      </ItemizedDebtsView>
    );
  }

  return (
    <StyledDetailCardContainer>
      <StyledDetailCard isEligible={props.optionInfo.isEligible}>
        <button className="back" onClick={props.onClickBack} data-test-label="back-button">
          Back
        </button>
        <div style={{ marginTop: "-80px" }}>
          <StyledDetailCardThumbContainer>
            {props.optionInfo.isEligible ? (
              <ThumbsUp />
            ) : props.optionInfo.isEligible !== false ? (
              <QuestionMark />
            ) : (
              <ThumbsDown />
            )}
          </StyledDetailCardThumbContainer>
          <div className="title_section section">
            <DetailHeading>{props.optionInfo.title}</DetailHeading>
            <DetailDescriptionCentered>{props.optionInfo.description}</DetailDescriptionCentered>

            {props.optionInfo.isEligible !== false && (
              <FinancialInfoContainer>
                <div className="items">
                  <div className="financial-item">
                    <p>${Math.round(props.optionInfo.paymentInformation.value).toLocaleString()}</p>
                    <strong>{props.optionInfo.paymentInformation.fieldName}</strong>
                  </div>
                  <div className="financial-item">
                    <p>{format(addMonths(new Date(), props.optionInfo.debtTimelineBreakdown.duration), "MMM y")}</p>
                    <strong>Debt-Free Date</strong>
                  </div>
                </div>
              </FinancialInfoContainer>
            )}

            {props.optionInfo.notEligibleMessage && (
              <IneligibleMessageBox>{props.optionInfo.notEligibleMessage}</IneligibleMessageBox>
            )}

            <div className="actions_section">
              <div className="button-container">
                <AIPromptButton
                  displayText={
                    props.optionInfo.isEligible
                      ? PROMPTS[props.optionInfo.optionName].ELIGIBLE
                      : (props.optionInfo.isEligible === undefined && PROMPTS[props.optionInfo.optionName]?.UNKNOWN) ||
                        PROMPTS[props.optionInfo.optionName].INELIGIBLE
                  }
                  onClick={() =>
                    props.onClickPromptButton(
                      props.optionInfo.isEligible
                        ? PROMPTS[props.optionInfo.optionName].ELIGIBLE
                        : (props.optionInfo.isEligible === undefined &&
                            PROMPTS[props.optionInfo.optionName]?.UNKNOWN) ||
                            PROMPTS[props.optionInfo.optionName].INELIGIBLE
                    )
                  }
                />
                {props.optionInfo.actions}
                <div className="disclaimer">
                  The estimates above reflect common interest rates, standard timelines, and the debts you've shared
                  with us.
                </div>
              </div>
            </div>
          </div>
        </div>

        {props.optionInfo.isEligible === true && (
          <div className="savings_section section">
            <SectionTitle>
              <div>COST BREAKDOWN</div>
              {showItemizedDebts && (
                <div>
                  <Button secondary small onClick={() => setIsItemizedDebtsViewOpen(true)}>
                    View included debts
                  </Button>
                </div>
              )}
            </SectionTitle>
            <SavingsTable>
              <div className="content">
                <div className="row">
                  <div className="description">
                    <div className="label">Eligible balance</div>
                    <div className="amount">${Math.round(props.unsecuredDebtEstimate).toLocaleString()}</div>
                  </div>
                </div>

                {props.optionInfo.savingsBreakdown.map((savingsBreakdownRow, index) => (
                  <div
                    className={`row ${index === props.optionInfo.savingsBreakdown.length - 1 ? "last-math-row" : ""}`}
                    key={savingsBreakdownRow.item?.toString()}
                  >
                    <div className="description">
                      <div className="label">{savingsBreakdownRow.item}</div>
                      <div className="amount">{savingsBreakdownRow.value}</div>
                    </div>
                  </div>
                ))}
                {/* TODO: undashed line */}

                <div className="row timeline">
                  <div className="description">
                    {/* TODO: include option name */}
                    <div className="label">Estimated Timeline</div>
                    <div className="amount">{formatMonths(props.optionInfo.debtTimelineBreakdown.duration)}</div>
                  </div>
                </div>

                <div className="row total">
                  <div className="description">
                    <div className="label">Total payment</div>
                    <div className="amount">${Math.round(props.optionInfo.totalPayment).toLocaleString()}</div>
                  </div>
                </div>
              </div>
            </SavingsTable>

            {/* <Small style={{ color: theme.colors.gray[500], marginTop: "8px" }}>
              *Calculations are estimated based on the information you provided. Upsolve does not provide legal advice,
              and you should confirm eligibility with a lawyer.
            </Small> */}

            {props.optionInfo.savingsQuestions.map((question) => (
              <AIPromptButton displayText={question} onClick={() => props.onClickPromptButton(question)} />
            ))}
          </div>
        )}
        <div className="good_for_section section">
          <b className="section-title">Common Questions</b>
          {props.optionInfo.faqQuestions.map((faqQuestion) => (
            <AIPromptButton displayText={faqQuestion} onClick={() => props.onClickPromptButton(faqQuestion)} />
          ))}
        </div>
        <div className="actions_section">
          <div className="button-container-bottom">{props.optionInfo.actions}</div>
        </div>
      </StyledDetailCard>
    </StyledDetailCardContainer>
  );
};
const StyledDetailCardContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
`;
const StyledDetailCard = styled.div<{ isEligible: boolean | undefined }>`
  display: flex;
  flex-direction: column;
  text-align: center;
  flex: 1;
  padding: 8px;
  max-width: 600px;
  gap: 24px;
  .back {
    color: black;
    background-color: ${theme.colors.white[700]};
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 12px;
    padding: 10px 24px;
    cursor: pointer;
    width: 20vw;
    max-width: 100px;
    margin-top: 30px;
    z-index: 99;
  }
  .section {
    padding: 32px 24px;
    @media (min-width: ${(props) => props.theme.breakpoints[500]}) {
      padding: 40px;
    }
    background: #ffffff;
    box-shadow: 0px 24px 24px rgba(0, 0, 0, 0.05), 0px 16px 24px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-size: 14px;
    .section-title {
      text-transform: uppercase;
      opacity: 80%;
      color: ${theme.colors.gray[500]};
      font-weight: 700;
    }
  }
  .title_section {
    border-radius: 8px;
    padding-top: 40px;
    padding-bottom: 24px;
    box-sizing: border-box;
    display: flex;
    gap: 0px;
    flex-direction: column;
    text-align: center;
    .disclaimer {
      font-size: 11px;
      line-height: 150%;
      color: ${theme.colors.gray[700]};
      opacity: 0.7;
    }
  }
  .savings_section {
    display: flex;
    flex-direction: column;
    .estimated_debt {
      display: flex;
      flex-direction: column;
    }
    .title {
      margin-bottom: 8px;
    }
    .payment {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;
    }
    .disclaimer {
      font-size: 11px;
      opacity: 0.7;
    }
  }
  .good_for_section {
  }
  .affordability_section {
  }
  .actions_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    .button-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .button-container-bottom {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 60px;
    }
  }

  .bottom_padding {
    padding: 20px;
  }

  .red {
    color: ${theme.colors.red[300]};
  }
  .green {
    color: ${theme.colors.green[300]};
  }
  .gray {
    color: ${theme.colors.gray[500]};
  }
  .info-row {
    display: flex;
    justify-content: space-between;
  }
  .info-row + .info-row {
    border-top: 1px dashed ${(props) => props.theme.colors.white[300]};
    margin-top: 5px;
    padding-top: 5px;
  }
`;

const StyledDetailCardThumbContainer = styled.div`
  position: relative;
  top: 38px;
`;

const IneligibleMessageBox = styled.div`
  font-size: 14px;
  text-align: start;
  width: 100%;
  margin-bottom: 1em;
  background-color: #f5f5f8;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .title {
    text-align: center;
  }
`;

const FinancialInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  .items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 32px;
    gap: 22px;
    width: 100%;
    box-sizing: border-box;
    @media (max-width: 768px) {
      padding: 0;
      gap: 12px;
    }
  }

  .financial-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;

    p {
      font-size: 20px;
      font-weight: bold;
      color: #333;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }

    strong {
      font-size: 12px;
      font-weight: 300;
      color: #666;

      @media (max-width: 768px) {
        margin-top: -4px;
        font-size: 10px;
      }
    }
  }
`;

const SectionTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #6b7280;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const SavingsTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px dotted ${theme.colors.white[100]};
    &:last-child {
      border-bottom: none;
    }
    .description {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-size: 14px;
      @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
        font-size: 12px;
      }
      .label {
        color: #4c4c4d;
        font-weight: 500;
      }
      .amount {
        color: #4c4c4d;
        font-weight: 700;
      }
    }
  }
  .total {
    border-bottom: none;
  }
  .last-math-row {
    border-bottom: 1px solid ${theme.colors.white[100]};
  }
`;

// Styled components for the modal
const ItemizedDebtsView = styled.div`
  background-color: white;
  border-radius: 16px;
  padding: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 0 auto;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const ModalTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
`;

const Disclaimer = styled.p`
  font-size: 12px;
  line-height: 120%;
  color: ${theme.colors.gray[500]};
  opacity: 0.7;
  text-align: left;
`;

const AccountList = styled.div`
  margin-bottom: 16px;
`;

const AccountItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px dashed ${theme.colors.white[100]};
  .account-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
`;

const AccountName = styled.div`
  font-weight: bold;
  font-size: 14px;
`;

const AccountType = styled.div`
  color: ${theme.colors.gray[500]};
  font-size: 12px;
`;

const AccountBalance = styled.div`
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
`;

const ChevronDownIcon = (props: { rotate: boolean }) => {
  return (
    <svg
      width="15"
      height="9"
      viewBox="0 0 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: props.rotate ? "rotate(180deg)" : "rotate(0deg)" }}
    >
      <path
        d="M6.78125 8.21875L0.78125 2.21875C0.375 1.84375 0.375 1.1875 0.78125 0.8125C1.15625 0.40625 1.8125 0.40625 2.1875 0.8125L7.5 6.09375L12.7812 0.8125C13.1562 0.40625 13.8125 0.40625 14.1875 0.8125C14.5938 1.1875 14.5938 1.84375 14.1875 2.21875L8.1875 8.21875C7.8125 8.625 7.15625 8.625 6.78125 8.21875Z"
        fill="#2C2EE0"
      />
    </svg>
  );
};
