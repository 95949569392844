import React from "react";
import styled from "styled-components";
import { theme } from "@upsolve/ui";
import { MessageText } from "./MessageText";

const QuestionSection = styled.div`
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  .question {
    color: ${theme.colors.gray[500]};
    font-style: italic;
  }
`;

const MessageContent = styled.div`
  word-break: break-word;
  overflow-wrap: break-word;
`;

interface Props {
  text: string;
}

export const QuestionAnswerFormat: React.FC<Props> = ({ text }) => {
  const parts = text.split("\n\n");
  const qaPairs = [];

  for (let i = 0; i < parts.length; i += 2) {
    if (parts[i]?.startsWith("Question: ") && parts[i + 1]?.startsWith("Answer: ")) {
      qaPairs.push({
        question: parts[i].replace("Question: ", ""),
        answer: parts[i + 1].replace("Answer: ", ""),
      });
    }
  }

  return (
    <MessageContent>
      {qaPairs.map((pair, index) => (
        <div key={index}>
          <QuestionSection>
            <div className="question">
              <MessageText text={pair.question} />
            </div>
          </QuestionSection>
          <QuestionSection>
            <div>
              <MessageText text={pair.answer} />
            </div>
          </QuestionSection>
        </div>
      ))}
    </MessageContent>
  );
};
