import { CLAIM_SUB_TYPES, CLAIM_TYPES } from "@upsolve/shared";

// from Claim.ts
// can remove a lot of these fields
export type MeridianLinkCreditReportClaim = {
  id?: number;
  type?: string;
  subType?: string;
  creditor?: {
    name?: string;
  };
  subTypeOtherDescription?: string;
  tertiaryType?: string;
  accountNumber?: string;
  date?: string;
  isContingent?: boolean;
  isDisputed?: boolean;
  isUnliquidated?: boolean;
  offset?: boolean;
  value?: number;
  valuePriority?: number;
  status?: string;
  lien?: string;
  createdAt?: Date;
  updatedAt?: Date;
};

const isDischargeableClaim = (claim: MeridianLinkCreditReportClaim) => {
  if (
    [
      CLAIM_SUB_TYPES.DOMESTIC_SUPPORT,
      CLAIM_SUB_TYPES.EDUCATION,
      CLAIM_SUB_TYPES.GOVERNMENT,
      CLAIM_SUB_TYPES.PERSONAL_INJURY,
      CLAIM_SUB_TYPES.RESTITUTION,
      CLAIM_SUB_TYPES.AUTOMOBILE, // in the questionnaire, we check for claims that users manually associate with a vehicle because some vehicle claims are dischargeable, if user surrenders their vehicle. For DebtAdvisor, assume noone will surrender vehicle.
    ].includes(claim.subType || "")
  ) {
    return false;
  }
  // this is also not done in the questionnaire because we have previously DQ'd property owners
  if (claim.type === CLAIM_TYPES.SECURED) {
    return false;
  }

  return true;
};
export const getDischargeableClaims = (claims: MeridianLinkCreditReportClaim[]) =>
  claims.filter((claim) => isDischargeableClaim(claim));

// for use in the "eligible debts" popup
export const getNonDischargeableClaims = (claims: MeridianLinkCreditReportClaim[]) =>
  claims.filter((claim) => !isDischargeableClaim(claim));
