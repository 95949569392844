import { kebabCase } from "lodash";
import { graphql, useStaticQuery } from "gatsby";
import Link from "gatsby-link";
import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { ButtonLink, IconBook, IconCaret, IconClipboard, IconPhoneChecklist, IconSearch, Small } from "@upsolve/ui";

import Logo from "../../../static/images/upsolve-logo-white.svg";
import { MyUpsolveLink } from "../Links/MyUpsolveLink";
import trackComponentAction from "../analytics/trackComponentAction";
import UpsolveSearchBar from "../search/UpsolveSearchBar";
import { CTATarget, getCTARenderedCopy, getCTATrackingTarget, getCTAURL } from "../CTA/getCTAData";
import { $TSFixMe } from "@upsolve/shared/dist/types";
import HelloBar from "./HelloBar";
import MenuIcon from "../../../static/images/icon-menu.svg";
import NavPopUp from "./NavPopUp";

import { CTATrackingTarget } from "../analytics/track";
import { DebtAdvisorModal } from "../DebtAdvisor/DebtAdvisorModal";
import { createPortal } from "react-dom";
import { orderBy } from "lodash";
import { learnTagsSortedByPriority } from "../Learn/learnTagPriority";
import getMyUpsolveEntryUrl from "../Links/getMyUpsolveEntryUrl";

const SparklesIcon = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.2188 3.1875L12 2.5L12.6562 0.75C12.6875 0.59375 12.8438 0.5 13 0.5C13.125 0.5 13.2812 0.59375 13.3125 0.75L14 2.5L15.75 3.1875C15.9062 3.21875 16 3.375 16 3.5C16 3.65625 15.9062 3.8125 15.75 3.84375L14 4.5L13.3125 6.28125C13.2812 6.40625 13.125 6.5 13 6.5C12.8438 6.5 12.6875 6.40625 12.6562 6.28125L12 4.5L10.2188 3.84375C10.0938 3.8125 10 3.65625 10 3.5C10 3.375 10.0938 3.21875 10.2188 3.1875ZM6.40625 2.8125L8.03125 6.375L11.5938 8C11.7812 8.09375 11.9062 8.28125 11.9062 8.46875C11.9062 8.65625 11.7812 8.84375 11.5938 8.90625L8.03125 10.5625L6.40625 14.125C6.3125 14.3125 6.125 14.4375 5.9375 14.4375C5.75 14.4375 5.5625 14.3125 5.5 14.125L3.84375 10.5625L0.28125 8.9375C0.09375 8.84375 0 8.65625 0 8.46875C0 8.28125 0.09375 8.09375 0.28125 8L3.84375 6.375L5.5 2.8125C5.5625 2.625 5.75 2.5 5.9375 2.5C6.125 2.5 6.3125 2.625 6.40625 2.8125ZM12 12.5L12.6562 10.75C12.6875 10.5938 12.8438 10.5 13 10.5C13.125 10.5 13.2812 10.5938 13.3125 10.75L14 12.5L15.75 13.1875C15.9062 13.2188 16 13.375 16 13.5C16 13.6562 15.9062 13.8125 15.75 13.8438L14 14.5L13.3125 16.2812C13.2812 16.4062 13.125 16.5 13 16.5C12.8438 16.5 12.6875 16.4062 12.6562 16.2812L12 14.5L10.2188 13.8438C10.0938 13.8125 10 13.6562 10 13.5C10 13.375 10.0938 13.2188 10.2188 13.1875L12 12.5Z"
      fill="currentColor"
    />
  </svg>
);

function getPath() {
  return typeof window === "undefined" ? "" : window.location.pathname;
}

const trackingProps = {
  componentName: "Header",
  componentVersion: "8",
};

type Props = {
  trackingPageContext: $TSFixMe;
  ctaTarget: CTATarget;
  allowMobileFooter?: boolean;
  onOpenTopOfFunnelAI?: () => void;
  shouldShowNavPopUp?: boolean;
};
/**
 * Header
 * - v0: Navbar with heavy link list under learning center
 * - v1: Navbar with search and simpler cta: 'Join Upsolve'
 * - v2: Navbar with fixed mobile footer and fixed desktop header navbar w/ bigger CTA
 * - v3: Popup message for app
 * - v4: Small popup activity shown above mobile footer
 * - v5: Added TIMES 100 inventions logo
 * - v6: Learn links point to category pages, not high value articles
 * - v8: added sign in button
 * @component
 * @version 8
 */
const Header = (props: Props) => {
  const { allowMobileFooter = true, onOpenTopOfFunnelAI, shouldShowNavPopUp } = props;
  const [isMobileMenuShowing, setIsMobileMenuShowing] = useState(false);
  const [isLearnPanelOpen, setLearnPanelOpen] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [showDebtTriageModal, setShowDebtTriageModal] = useState(false);

  const onClickTopOfFunnelAI = () => {
    if (onOpenTopOfFunnelAI) {
      onOpenTopOfFunnelAI();
    }
  };
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      learnArticleTags: allContentfulLearnArticleTag(
        filter: { hasDirectoryPage: { eq: true } }
        sort: { fields: [label], order: ASC }
      ) {
        nodes {
          descriptionLong {
            descriptionLong
          }
          hasDirectoryPage
          label
          value
          type
        }
      }
    }
  `);

  const usefulLearnTags = data.learnArticleTags.nodes.filter(
    (tag: { type: string; value: string }) => tag.type === "category" && !["chapter11", "divorce"].includes(tag.value)
  );
  const usefulLearnMenuTags = orderBy(
    usefulLearnTags,
    [(t) => Object.values(learnTagsSortedByPriority).indexOf(t.value)],
    ["asc"]
  );

  // this button always opens the triage
  const onClickGetDebtHelpButton = () => {
    trackComponentAction({
      ...trackingProps,
      actionId: `header.CTAButton.${getCTATrackingTarget(props.ctaTarget)}`,
      actionMethod: "click",
      pageContext: props.trackingPageContext,
    });
    setShowDebtTriageModal(true);
  };
  const onClickSignInButton = () => {
    trackComponentAction({
      ...trackingProps,
      actionId: `header.signInButton.${CTATrackingTarget.LOGIN}`,
      actionMethod: "click",
      pageContext: props.trackingPageContext,
    });
  };
  const onClickNewMobileFooterCTA = () => {
    trackComponentAction({
      ...trackingProps,
      actionId: `mobileFooter.CTAButton.CTA.myUpsolveNavigation`,
      actionMethod: "click",
      pageContext: props.trackingPageContext,
    });
  };
  const onClickOldMobileFooterCTA = () => {
    trackComponentAction({
      ...trackingProps,
      actionId: `mobileFooter.CTAButton.${getCTATrackingTarget(props.ctaTarget)}`,
      actionMethod: "click",
      pageContext: props.trackingPageContext,
    });
    if (props.ctaTarget === CTATarget.DEBT_TRIAGE) setShowDebtTriageModal(true);
  };

  // eventually update all pages to use the new nav, they'll each need to add the ai element. maybe we move it up to TopLevelLayout
  const OldMobileFooterNav = () => (
    <MobileFooterNav>
      <div className="contents">
        <Link
          className={
            getPath().includes("/learn/") && !getPath().includes("/learn/how-to-file-bankruptcy/") ? "active" : ""
          }
          onClick={(ev) => {
            if (getPath().includes("/learn/") && !getPath().includes("how-to-file-bankruptcy")) {
              ev.preventDefault();
            }
          }}
          to="/learn/"
        >
          <div>
            <IconBook />
            Free Articles
          </div>
        </Link>
        <MyUpsolveLink href={getCTAURL(props.ctaTarget)} onClick={onClickOldMobileFooterCTA}>
          <div className="checklist">
            <IconPhoneChecklist />
            {getCTARenderedCopy(props.ctaTarget).mobileFooterText}
          </div>
        </MyUpsolveLink>
        <Link
          className={getPath().includes("/learn/how-to-file-bankruptcy/") ? "active" : ""}
          to="/learn/how-to-file-bankruptcy/"
        >
          <div>
            <IconClipboard />
            Filing Guide
          </div>
        </Link>
      </div>
    </MobileFooterNav>
  );
  const NewMobileFooterNav = () => (
    <MobileFooterNav>
      <div className="contents">
        <div className="mobile-nav-item" onClick={onClickTopOfFunnelAI}>
          <div>
            <SparklesIcon />
            Ask a Question
          </div>
        </div>
        <MyUpsolveLink href={getMyUpsolveEntryUrl()} onClick={onClickNewMobileFooterCTA}>
          <div className="checklist">
            <IconPhoneChecklist />
            File Bankruptcy
          </div>
        </MyUpsolveLink>
        <Link to="https://upsolve.org/how-we-work/">
          <div>
            <IconClipboard />
            How we work
          </div>
        </Link>
      </div>
    </MobileFooterNav>
  );

  // RENDER
  return (
    <>
      <NavBar>
        <div className="hellobar-container">
          <StudentLoansCTAHelloBar />
        </div>
        <nav className="nav">
          <div className="nav__lead">
            <span role="button" aria-label="Toggle Menu">
              <Link to="/">
                Upsolve
                <Logo />
              </Link>
            </span>
            <Small>a nonprofit</Small>
          </div>
          <nav className={`nav__links `}>
            <div>
              <Link aria-busy="false" to="/bankruptcy/">
                File Bankruptcy
              </Link>
            </div>
            <div>
              <Link aria-busy="false" to="/learn/transparency/">
                How It's Free
              </Link>
            </div>
            <div>
              <Link aria-busy="false" to="/reviews/">
                Member Reviews
              </Link>
            </div>
            <div onMouseEnter={() => setLearnPanelOpen(true)} onMouseLeave={() => setLearnPanelOpen(false)}>
              <Link aria-busy="false" to="/learn/">
                Learn&nbsp;
                <span className="caret">
                  <IconCaret />
                </span>
              </Link>
            </div>
          </nav>

          <div className="nav__ctas">
            <span
              className="nav__ctas__search"
              onClick={(ev) => {
                if (!getPath().includes("/search")) setSearchOpen(true);
              }}
            >
              <IconSearch color="white" />
            </span>
            <ButtonLink onClick={onClickSignInButton} href={"https://my.upsolve.org/login"} inverted>
              Sign In
            </ButtonLink>
            <GetDebtHelpButton onClickGetDebtHelpButton={onClickGetDebtHelpButton} />
          </div>
          <div className="nav__cta__mobile">
            <span
              className="nav__ctas__search"
              onClick={(ev) => {
                if (!getPath().includes("/search")) setSearchOpen(true);
              }}
            >
              <IconSearch color="white" />
            </span>
            <MenuIcon onClick={() => setIsMobileMenuShowing(!isMobileMenuShowing)} />
          </div>
        </nav>
        {isSearchOpen && (
          <UpsolveSearchBar
            autoFocus={true}
            defaultValue=""
            onClose={() => setSearchOpen(false)}
            onSearch={() => setSearchOpen(false)}
            position="navbar"
          />
        )}
      </NavBar>
      <LearnCategoryMenu
        className={`${isLearnPanelOpen ? "show" : ""}`}
        onMouseEnter={() => setLearnPanelOpen(true)}
        onMouseLeave={() => setLearnPanelOpen(false)}
      >
        <ul>
          {usefulLearnMenuTags.map((tag: { label: string; value: string }) => (
            <li key={tag.label}>
              <Link
                aria-busy="false"
                to={`/learn/category/${kebabCase(tag.value)}/`}
                onClick={() => {
                  setLearnPanelOpen(false);
                }}
              >
                {tag.label}
              </Link>
            </li>
          ))}
        </ul>
      </LearnCategoryMenu>

      {allowMobileFooter !== false && (
        <Fragment>
          {!!onOpenTopOfFunnelAI ? <NewMobileFooterNav /> : <OldMobileFooterNav />}
          {shouldShowNavPopUp && <NavPopUp trackingPageContext={props.trackingPageContext} target={props.ctaTarget} />}
        </Fragment>
      )}
      {showDebtTriageModal &&
        createPortal(
          <DebtAdvisorModal
            trackingPageContext={props.trackingPageContext}
            onHide={() => setShowDebtTriageModal(false)}
          />,
          document.body
        )}
      {isMobileMenuShowing && (
        <MobileMenu onClose={() => setIsMobileMenuShowing(false)} onClickSignInButton={onClickSignInButton} />
      )}
    </>
  );
};

const StyledMobileMenu = styled.nav`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  top: 0px;
  .menu {
    display: flex;
    z-index: 999;
    width: 70%;
    height: 100%;
    padding: 30px;
    font-size: 18px;
    justify-content: space-between;
    flex: 2;
    background-color: ${(props) => props.theme.colors.gray[300]};
    opacity: 1;
    animation: menu 0.1s linear;

    .links {
      padding-top: 12px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      a {
        color: white !important;
      }
      flex: 1;
      text-align: right;
    }
    .close-button-container {
      display: flex;
      justify-content: flex-end;
      color: ${(props) => props.theme.colors.gray[800]};
      .close-button {
        font-size: 24px;
      }
    }
    @keyframes menu {
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translate(0%);
      }
    }
  }

  .outside-menu {
    flex: 1.5;
  }
`;
const MobileMenu = ({ onClose, onClickSignInButton }: { onClose: () => void; onClickSignInButton: () => void }) => {
  return (
    <StyledMobileMenu>
      <div className="outside-menu" onClick={onClose} />
      <div className="menu">
        <div className="close-button-container">
          <div className="close-button" onClick={onClose}>
            X
          </div>
        </div>
        <div className="links">
          <Link aria-busy="false" to="/bankruptcy/">
            File Bankruptcy
          </Link>
          <Link aria-busy="false" to="/learn/transparency/">
            How It's Free
          </Link>
          <Link aria-busy="false" to="/reviews/">
            Member Reviews
          </Link>
          <Link aria-busy="false" to="/learn/">
            Learn
          </Link>
          <a onClick={onClickSignInButton} aria-busy="false" href="https://my.upsolve.org/login">
            Sign In
          </a>
        </div>
      </div>
    </StyledMobileMenu>
  );
};
const StudentLoansCTAHelloBar = () => (
  <HelloBar
    helloBarText={
      <span>
        Ready to say goodbye to debt for good?&nbsp;
        <b>Learn More</b>
      </span>
    }
    helloBarUrl="/bankruptcy/"
    helloBarTrackingActionId="CTA.ARTICLE_STUDENT_LOANS"
  />
);

const TRANSITION_TIMER = "250ms";

const NavBar = styled.header`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 999;
  position: sticky;
  top: 0px;
  .nav {
    background: ${(props) => props.theme.colors.brand[500]};
    box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.025);
    display: flex;
    justify-content: space-between;
  }
  a {
    color: ${(props) => props.theme.colors.white[900]};
    &:hover {
      color: ${(props) => props.theme.colors.brand[600]};
      path {
        fill: ${(props) => props.theme.colors.brand[600]};
      }
    }
    font-size: 14px;
    button {
      font-weight: 700;
    }
  }

  .nav__lead,
  .nav__links,
  .nav__ctas {
    flex-grow: 1;
    height: 54px;
  }

  .nav__lead {
    max-width: 150px;
    display: inline-flex;
    align-items: center;
    margin-left: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    small {
      font-size: 10px;
      color: white;
      padding-left: 4px;
    }
    svg {
      display: block;
      height: 32px;
      width: 140px;
      margin-top: -2px;
      @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
        height: 28px;
        width: 120px;
        margin-top: 0;
      }
    }
    span > a {
      display: flex;
      font-size: 0;
    }
    @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
      margin-left: 0.9em;
    }
  }
  .nav__links {
    display: flex;
    .caret > svg {
      height: 8px;
      width: 8px;
      path {
        fill: ${(props) => props.theme.colors.white[900]};
      }
    }

    // Desktop (Wide)
    @media (min-width: ${(props) => props.theme.breakpoints[750]}) {
      flex-grow: 1;
      justify-content: center;
      & > div {
        display: flex;
        align-items: center;
        & > a {
          padding: 0.75em;
          font-weight: 500;
        }
      }
    }
    // Mobile (Slim)
    @media (max-width: ${(props) => props.theme.breakpoints[750]}) {
      position: fixed;
      overflow-y: scroll;
      width: 100vw;
      height: 90vh;
      z-index: 998;
      transform: translateY(100vh);
      padding: 0 0 3em;
      &.show {
        transform: translateY(10vh);
      }
      & > div {
        padding: 1em;
        &:first-of-type {
          margin-top: 1em;
        }
        &:last-of-type {
          margin-bottom: 2.5em;
        }
        & > a {
          font-size: 18px;
          color: ${(props) => props.theme.colors.white[900]};
        }
      }
    }
  }
  .nav__cta__mobile {
    display: none;
    align-items: center;
    justify-content: center;
    margin-right: 0.5em;

    @media (max-width: ${(props) => props.theme.breakpoints[750]}) {
      display: flex;
    }
    svg {
      width: 50px;
      height: auto;
      path {
        fill: white;
      }
    }
  }
  .nav__ctas {
    display: flex;
    flex-grow: 0;
    gap: 8px;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: ${(props) => props.theme.breakpoints[750]}) {
      display: none;
    }
    & > * {
      margin-right: 0.5em;
    }
    & > a {
      padding-left: 6px;
      padding-right: 6px;
      color: ${(props) => props.theme.colors.white[900]};
      font-weight: 500;
      &:last-child {
        margin-right: 1.25em;
      }
      &:hover {
        cursor: pointer;
        color: ${(props) => props.theme.colors.brand[600]};
      }
    }
  }
  .nav__ctas__search {
    height: 36px;
    width: 36px;
    margin: 4px 0;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: white;
    color: white;
    cursor: pointer;
    &:hover {
      path {
        fill: ${(props) => props.theme.colors.brand[600]};
      }
    }
    svg {
      height: 32px;
      min-height: 32px;
      position: relative;
      top: -1px;
    }
  }
  & > a {
    @media (max-width: ${(props) => props.theme.breakpoints[750]}) {
      display: none;
    }
  }

  // Handle transparent background options
  transition: background ${TRANSITION_TIMER}, box-shadow ${TRANSITION_TIMER};
  .nav__lead svg path,
  .nav__links .caret svg > path,
  .nav__ctas__search svg path,
  .nav__links > div > a,
  div.nav__ctas > a {
    transition: fill ${TRANSITION_TIMER}, color ${TRANSITION_TIMER}, border-color ${TRANSITION_TIMER};
  }
`;

const LearnCategoryMenu = styled.nav`
  display: none;
  /* TODO: do a pass on these links */
  // Desktop (Wide)
  @media (min-width: ${(props) => props.theme.breakpoints[750]}) {
    width: 100vw;
    position: fixed;
    z-index: 998;
    left: 0;
    right: 0;
    background: ${(props) => props.theme.colors.brand[500]};
    box-shadow: 0 5px 15px -5px rgba(0, 0, 25, 0.2);
    padding: 0.75em 2em;
    &.show {
      &,
      ul {
        display: grid;
        list-style-type: none;
        margin: 0;
      }
      ul,
      li {
        grid-gap: 6px;
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
      }
      li {
        text-align: center;
        border-radius: 4px;
        background: ${(props) => props.theme.colors.white[900]};
        border: 1px solid ${(props) => props.theme.colors.white[500]};
        display: flex;
        a {
          width: 100%;
          text-align: center;
        }
      }
    }
    a {
      font-size: 13px;
    }
    div {
      margin: 1em 0;
    }
    ul {
      padding: 0 0.5em 0 1em;
      list-style-type: disc;
      flex: 1;
    }
    li {
      margin-bottom: 0.2em;
      color: ${(props) => props.theme.colors.brand[500]};
    }
  }
  // Mobile (Slim)
  @media (max-width: ${(props) => props.theme.breakpoints[750]}) {
    div {
      margin: 1em 0;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      &:first-of-type {
        margin-top: 1em;
      }
      &:last-of-type {
        margin-bottom: 1em;
      }
    }
    li {
      margin-bottom: 0.65em;
      list-style-type: disc;
      color: ${(props) => props.theme.colors.brand[500]};
      margin-left: 1.25em;
      a {
        font-size: 15px;
      }
    }
  }
`;
// TODO: Move to another file
const MobileFooterNav = styled.div`
  display: none;
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  height: 82px;
  background: white;
  box-shadow: 0 0 32px 12px rgba(0, 0, 55, 0.15);

  @media (max-width: ${(props) => props.theme.breakpoints[750]}) {
    display: flex;
    padding: 0 1.5em;
  }
  @media (max-width: 360px) {
    padding: 0 0.25em;
  }
  .contents {
    height: 82px;
    max-height: 82px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(3, 1fr);

    & > a,
    .mobile-nav-item {
      white-space: nowrap;
      margin: 0 12px;
      padding: 6px 0 3px;
      border-bottom: 3px solid ${(props) => props.theme.colors.gray[900]};
      &.active {
        div {
          color: ${(props) => props.theme.colors.brand[500]};
        }
        svg path {
          fill: ${(props) => props.theme.colors.brand[500]};
        }
        border-bottom: 3px solid ${(props) => props.theme.colors.brand[500]};
      }
    }
    & > a,
    div,
    .mobile-nav-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 12px;
      color: ${(props) => props.theme.colors.gray[500]};
      svg {
        height: 26px;
        path {
          fill: ${(props) => props.theme.colors.gray[500]};
        }
      }
    }
    .checklist {
      white-space: nowrap;
      svg {
        height: 37px;
        width: 100%;
      }
    }
  }
`;

const GetDebtHelpButton = ({ onClickGetDebtHelpButton }: { onClickGetDebtHelpButton: () => void }) => {
  return <StyledButton onClick={onClickGetDebtHelpButton}>Get Debt Help</StyledButton>;
};
const StyledButton = styled.button`
  display: flex;
  width: 166.667px;
  height: 40px;
  padding: 6.667px 10.667px;
  justify-content: center;
  align-items: center;
  gap: 5.333px;
  border-radius: 12px;
  border: 0.667px solid #d0d5dd;
  background: white;
  color: ${(props) => props.theme.colors.brand[500]};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 100% */
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.colors.brand[600]};
  }
  @media screen and (max-width: 940px) {
    width: 180px;
    height: 40px;
    padding: 10px 16px;
    gap: 8px;
    border-radius: 31px;
    font-size: 16px;
    line-height: 20px; /* 125% */
    margin-right: 0.9em;
  }
`;

export default Header;
