import React, { useEffect, useReducer, useState } from "react";
import { FullScreenModalClose, ModalClose, ViewportCenteredFixed } from "../modals/Modal";
import { StyledOverlayBackdrop } from "../modals/StyledOverlayBackdrop";
import { FullScreenTriageModal, TriageModal } from "../modals/TriageModal";
import { track } from "../analytics/track";
import { EVENTS } from "@upsolve/shared";
import { $TSFixMe } from "@upsolve/shared/dist/types";
import { initialFormState, screens, TFormState } from "./Screens";
import styled from "styled-components";
import Hotjar from "@hotjar/browser";
import { TFlow, useFlowManager } from "./useFlowManager";

type OnHideFunction = (...args: any[]) => void;

interface ProductSelectorModalProps {
  onHide: OnHideFunction;
  trackingPageContext: $TSFixMe;
}

const DebtAdvisorStyle = styled.div`
  font-size: 14px;
`;

const DebtAdvisorFlow: TFlow<TFormState, keyof typeof screens> = (state: TFormState) => [
  { stepName: "Intro" },
  { stepName: "DebtInputAsk" },
  {
    //user did not already pull debts
    showIf: state.wantsToPullDebts === true && state.claims.length === 0,
    stepName: "CreditPullScreen",
  },
  {
    showIf: state.wantsToPullDebts === false || state.creditPullFailed,
    stepName: "DebtAsk",
  },
  {
    showIf: state.wantsToPullDebts === false || state.creditPullFailed,
    stepName: "ZipcodeAsk",
  },
  { stepName: "HouseholdAsk" },
  { stepName: "HasIncomeScreen" },
  {
    showIf: state.hasIncome === true,
    stepName: "MeansTestAsk",
  },
  { stepName: "AvailableMonthlyFundsAsk" },
  // if the user has a high amount of available monthly funds, we want to confirm with them that they're sure they can afford to pay off their debt
  {
    showIf: state.availableMonthlyFunds !== undefined && state.availableMonthlyFunds > 500,
    stepName: "HighAmountConfirmation",
  },
  //failing the means test means they have significant income, so now we want
  //to see if they qualify for debt settlement
  {
    showIf: state.passesMeansTest === false,
    stepName: "AccessToCapitalAsk",
  },
  { stepName: "HasGoodCreditScoreAsk" },
  { stepName: "CalculatingScreen" },
  { stepName: "StatusQuoScreen" },
  { stepName: "ResultsScreen" },
];

export const DebtAdvisorModal: React.FC<ProductSelectorModalProps> = ({ onHide, trackingPageContext }) => {
  const { step, dispatch, formState } = useFlowManager<TFormState, keyof typeof screens>(
    "Intro",
    initialFormState,
    DebtAdvisorFlow
  );

  const onNext = () => {
    dispatch({ type: "goToNextStep" });
  };

  const onBack = () => {
    dispatch({ type: "goToPreviousStep" });
  };

  const onRestart = () => {
    //keep claims retrieved previously, unsecuredDebtEstimate, and zipcode so user does not have to go through credit pull step again
    dispatch({
      type: "restart",
      restartState: {
        ...initialFormState,
        claims: formState.claims,
        unsecuredDebtEstimate: formState.unsecuredDebtEstimate,
        zipcode: formState.zipcode,
      },
    });
  };

  const updateState = (state: Partial<TFormState>) => {
    dispatch({ type: "formStateUpdate", payload: state });
  };

  useEffect(() => {
    Hotjar.event("debtAdvisor.start");
    track(EVENTS.COMPONENT_ACTION, {
      componentName: "debtAdvisor",
      componentVersion: "2.1",
      actionId: `opened`,
      actionMethod: "click",
      pageContext: trackingPageContext,
    });
  }, []);

  const trackStepAction = (actionId: string, extras?: Record<string, any>) => {
    track(EVENTS.COMPONENT_ACTION, {
      componentName: `debtAdvisor.${step}`,
      componentVersion: "2.1",
      actionId,
      actionMethod: "click",
      pageContext: trackingPageContext,
      extras,
    });
  };

  const trackImpression = (subComponentName: string) => {
    track(EVENTS.COMPONENT_IMPRESSION, {
      componentName: `debtAdvisor.${step}.${subComponentName}`,
      componentVersion: "2.1",
      pageContext: trackingPageContext,
    });
  };

  const handleClose = () => {
    track(EVENTS.COMPONENT_ACTION, {
      componentName: "debtAdvisor",
      componentVersion: "2.1",
      actionId: `closed.${step}`,
      actionMethod: "click",
      pageContext: trackingPageContext,
    });
    onHide();
  };

  const CurrentScreen = screens[step];

  // Dropoff tracker
  useEffect(() => {
    track(EVENTS.COMPONENT_IMPRESSION, {
      componentName: `debtAdvisor.${step}`,
      componentVersion: "2.1",
      pageContext: trackingPageContext,
    });
  }, [step]);
  const shouldShowModalClose = !formState.isDetailsOpen;

  //TODO: clean all this up
  if (["CalculatingScreen", "ResultsScreen", "StatusQuoScreen"].includes(step)) {
    return (
      <DebtAdvisorStyle>
        <ViewportCenteredFixed>
          <FullScreenTriageModal>
            {shouldShowModalClose && <FullScreenModalClose onClick={handleClose} />}
            <CurrentScreen
              controls={{ onNext, onBack, onRestart }}
              state={formState}
              updateState={updateState}
              trackStepAction={trackStepAction}
              trackImpression={trackImpression}
            />
          </FullScreenTriageModal>
        </ViewportCenteredFixed>
      </DebtAdvisorStyle>
    );
  }

  return (
    <DebtAdvisorStyle>
      <ViewportCenteredFixed>
        <TriageModal>
          <ModalClose onClick={handleClose} />
          {/* TODO: fix position of elements so things aren't jumping around everywhere
            {step !== "Intro" && <BackButton onClick={onBack}>← Back</BackButton>} */}
          <CurrentScreen
            controls={{ onNext, onBack, onRestart }}
            state={formState}
            updateState={updateState}
            trackStepAction={trackStepAction}
            trackImpression={trackImpression}
          />
        </TriageModal>
      </ViewportCenteredFixed>
      <StyledOverlayBackdrop onClick={handleClose} />
    </DebtAdvisorStyle>
  );
};
