import React, { useState } from "react";
import { Input } from "./Components";

interface DatePickerProps {
  onChange: (date: Date) => void;
  "data-test-label"?: string;
  invalid?: boolean;
}

// TODO: move state up to parent component for validation
const DatePicker: React.FC<DatePickerProps> = ({ onChange, "data-test-label": testLabel, invalid }) => {
  const [selectedDate, setSelectedDate] = useState<string>("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
    const date = new Date(event.target.value);
    if (!isNaN(date.getTime())) {
      onChange(date);
    }
  };

  return (
    <div>
      <Input invalid={invalid} type="date" value={selectedDate} onChange={handleChange} data-test-label={testLabel} />
    </div>
  );
};

export default DatePicker;
