import React, { useEffect, useState } from "react";
import { ModalContent } from "../modals/TriageModal";
import {
  IntroHeading,
  DescriptionCentered,
  Input,
  Button,
  QuestionHeading,
  Controls,
  DetailDescriptionCentered,
} from "./Components";
import DatePicker from "./DatePicker";
import { TScreenProps } from "./Screens";
import styled from "styled-components";
import { getStateFromZipcode, STATES } from "@upsolve/shared";
import { findOrCreateAnonymousId } from "./useChat";
import {
  getDischargeableClaims,
  MeridianLinkCreditReportClaim,
} from "./meridianLinkCreditReport/meridianLinkCreditReportUtils";
import { theme } from "@upsolve/ui";
import axios from "axios";

export const CreditPullScreen = ({ controls, updateState, trackImpression, trackStepAction }: TScreenProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<{ claims: MeridianLinkCreditReportClaim[] } | null>(null);

  const fetchMeridianLinkCreditReport = async (userInfo: MeridianLinkUserInfo, userId: string) => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${UPSOLVE_API_URL}/v1/debtAdvisor/pullCreditReport`, {
        userInfo,
        userId,
      });
      setData(response.data);
      setIsLoading(false);
      setError(null);
    } catch (error) {
      setError(error as Error);
      setIsLoading(false);
    }
  };
  const userId = findOrCreateAnonymousId();

  // TODO: handle situation where user has no claims + loading + error
  const onSubmitUserInfo = (userInfo: MeridianLinkUserInfo) => {
    fetchMeridianLinkCreditReport(userInfo, userId);
    updateState({ zipcode: userInfo.zipcode });
  };

  if (error) {
    return (
      <CreditPullErrorScreen
        onSubmit={() => {
          updateState({ creditPullFailed: true });
          controls.onNext();
        }}
        trackStepAction={trackStepAction}
        error={error}
      />
    );
  }

  if (!data) {
    return (
      <CreditPullRequest onSubmitUserInfo={onSubmitUserInfo} isLoading={isLoading} trackImpression={trackImpression} />
    );
  }

  const claims = data?.claims;
  const orderedClaimsWithValues =
    claims?.filter((claim) => !!claim.value && claim.value > 0).sort((a, b) => (b.value || 0) - (a.value || 0)) || [];
  const totalDebt = orderedClaimsWithValues?.reduce((acc, claim) => acc + (claim.value || 0), 0) || 0;
  const totalDischargeableDebt =
    getDischargeableClaims(orderedClaimsWithValues).reduce((acc, claim) => acc + (claim.value || 0), 0) || 0;

  if (orderedClaimsWithValues.length === 0) {
    const error = new Error("no claims were retrieved from credit pull");
    return (
      <CreditPullErrorScreen
        onSubmit={() => {
          updateState({ creditPullFailed: true });
          controls.onNext();
        }}
        trackStepAction={trackStepAction}
        error={error}
      />
    );
  }

  return (
    <CreditReportDetailsScreen
      claims={orderedClaimsWithValues}
      totalDebt={totalDebt}
      onSubmit={() => {
        updateState({
          unsecuredDebtEstimate: totalDischargeableDebt,
          claims: orderedClaimsWithValues,
          creditPullFailed: false, //(in case user retries)
        });
        controls.onNext();
      }}
      trackStepAction={trackStepAction}
    />
  );
};
const CreditPullErrorScreen = (props: {
  onSubmit: () => void;
  trackStepAction: (actionId: string, extras?: Record<string, any>) => void;
  error: Record<string, any>;
}) => {
  useEffect(() => {
    // this is just so we can easily track reasons for errors.
    props.trackStepAction("error", { errorMessage: String(props.error) });
  }, []);
  return (
    <ModalContent>
      <IntroHeading>We were unable to verify your identity</IntroHeading>
      <DescriptionCentered>
        {/* TODO: improve this copy */}
        You can give an estimate of your debt to explore debt relief options that might be a good fit for you.
      </DescriptionCentered>
      <Button onClick={props.onSubmit}>Next</Button>
    </ModalContent>
  );
};

const StyledSelect = styled.select`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 10px;
  width: 174px;
  height: 59px;
  background: #ffffff;
  border: 1px solid #d0d0d4;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  /* Remove default select arrow */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  /* Add custom arrow */
  background-image: url("data:image/svg+xml,${encodeURIComponent(
    '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.28125 12.7188L1.28125 6.71875C0.875 6.34375 0.875 5.6875 1.28125 5.3125C1.65625 4.90625 2.3125 4.90625 2.6875 5.3125L8 10.5938L13.2812 5.3125C13.6562 4.90625 14.3125 4.90625 14.6875 5.3125C15.0938 5.6875 15.0938 6.34375 14.6875 6.71875L8.6875 12.7188C8.3125 13.125 7.65625 13.125 7.28125 12.7188Z" fill="#989899"/></svg>'
  )}");
  background-repeat: no-repeat;
  background-position: right 16px center;
  padding-right: 48px;
`;

type TCreditPullRequestProps = {
  onSubmitUserInfo: (userInfo: MeridianLinkUserInfo) => void;
  isLoading: boolean;
  trackImpression: (subcomponentName: string) => void;
};

interface MeridianLinkUserInfo {
  firstName: string;
  middleName: string;
  lastName: string;
  ssn: string;
  street1: string;
  city: string;
  state: string;
  zipcode: string;
  birthDate: string;
}

// TODO: remove unnecessary fields from fe and be. I was able to pull credit with incorrect middlename and zip, idk if those are necessaary
const CreditPullRequest = (props: TCreditPullRequestProps) => {
  const [userInfo, setUserInfo] = useState<MeridianLinkUserInfo>({
    firstName: "",
    middleName: "",
    lastName: "",
    ssn: "",
    street1: "",
    city: "",
    state: "AL",
    zipcode: "",
    birthDate: "",
  });
  const [consent, setConsent] = useState(false);

  useEffect(() => {
    props.trackImpression("request");
  }, []);

  const isFirstNameValid = userInfo.firstName.trim() !== "";
  const isLastNameValid = userInfo.lastName.trim() !== "";
  const isSSNValid = /^(?!666|000|9\d{2})\d{3}-?(?!00)\d{2}-?(?!0000)\d{4}$/.test(userInfo.ssn);
  const isZipcodeValid = !!getStateFromZipcode(userInfo.zipcode);
  const isBirthDateValid = !isNaN(Date.parse(userInfo.birthDate));
  const isStreetValid = userInfo.street1.trim() !== "" && /\d/.test(userInfo.street1 || "");
  const isCityValid = userInfo.city.trim() !== "";

  const isSubmitDisabled =
    !isFirstNameValid ||
    !isLastNameValid ||
    !isSSNValid ||
    !isStreetValid ||
    !isCityValid ||
    !userInfo.state ||
    !isZipcodeValid ||
    !isBirthDateValid ||
    !consent ||
    props.isLoading;

  return (
    <StyledCreditPullRequest>
      <IntroHeading>Your information is safe with us!</IntroHeading>
      <DescriptionCentered>
        We take your privacy seriously. With your permission, we'll perform a soft pull of your credit report—this won't
        affect your score. Your information is securely encrypted and never shared without your consent.
      </DescriptionCentered>
      <div className="form-container">
        <div className="labeledInput content-node__input">
          <label>First Name</label>
          <Input
            type="string"
            placeholder="John"
            onChange={(e) => setUserInfo({ ...userInfo, firstName: e.target.value })}
            value={userInfo.firstName}
            required
            invalid={!!userInfo.firstName && !isFirstNameValid}
          />
        </div>
        <div className="labeledInput content-node__input">
          <label>Middle Name</label>
          <Input
            type="string"
            placeholder="Jacob"
            onChange={(e) => setUserInfo({ ...userInfo, middleName: e.target.value })}
            value={userInfo.middleName}
          />
        </div>
        <div className="labeledInput content-node__input">
          <label>Last Name</label>
          <Input
            type="string"
            placeholder="Doe"
            onChange={(e) => setUserInfo({ ...userInfo, lastName: e.target.value })}
            value={userInfo.lastName}
            data-test-label="lastName"
            required
            invalid={!!userInfo.lastName && !isLastNameValid}
          />
        </div>
        <div className="labeledInput content-node__input">
          <label>Social Security Number</label>
          <Input
            type="string"
            placeholder="123-45-6789"
            onChange={(e) => setUserInfo({ ...userInfo, ssn: e.target.value })}
            value={userInfo.ssn}
            required
            invalid={!!userInfo.ssn && !isSSNValid}
          />
        </div>
        <div className="labeledInput content-node__input date">
          <label>Date of Birth</label>
          <DatePicker
            onChange={(date) => setUserInfo({ ...userInfo, birthDate: date.toISOString() })}
            data-test-label="birthDate"
            invalid={!isBirthDateValid}
          />
        </div>
        <div className="labeledInput content-node__input">
          <label>Street Address</label>
          <Input
            type="string"
            placeholder="123 Main Street"
            onChange={(e) => setUserInfo({ ...userInfo, street1: e.target.value })}
            value={userInfo.street1}
            invalid={!!userInfo.street1 && !isStreetValid}
          />
        </div>
        <div className="location-container">
          <div className="labeledInput content-node__input">
            <label>City</label>
            <Input
              type="string"
              placeholder="San Francisco"
              onChange={(e) => setUserInfo({ ...userInfo, city: e.target.value })}
              value={userInfo.city}
              invalid={!!userInfo.city && !isCityValid}
            />
          </div>
          <div className="labeledInput content-node__input">
            <label>State</label>
            <StyledSelect
              data-test-label="state"
              placeholder="Select"
              onChange={(e) => setUserInfo({ ...userInfo, state: e.target.value })}
            >
              {Object.entries(STATES).map(([abbreviation, value]) => (
                <option key={abbreviation} value={abbreviation}>
                  {abbreviation}
                </option>
              ))}
            </StyledSelect>
          </div>
          <div className="labeledInput content-node__input">
            <label>Zip</label>
            <Input
              type="number"
              placeholder="11111"
              onChange={(e) => setUserInfo({ ...userInfo, zipcode: e.target.value })}
              value={userInfo.zipcode}
              data-test-label="zipcode"
              invalid={!!userInfo.zipcode && !isZipcodeValid}
            />
          </div>
        </div>
        <div className="consent-container" onClick={() => setConsent(!consent)}>
          <div className="consent-checkbox-container content-node__input">
            <Input className="consent-checkbox" type="checkbox" checked={consent} data-test-label="consent" />
          </div>
          <div className="consent-text">
            I/We understand that by clicking on the checkbox immediately preceding this notice, you are providing
            "written instruction" to UPSOLVE under the Fair Credit Reporting Act authorizing UPSOLVE to obtain
            information from your personal credit profile or other information from the National Credit Bureaus.
          </div>
        </div>
        <Button disabled={isSubmitDisabled} onClick={() => props.onSubmitUserInfo(userInfo)}>
          Next
        </Button>
      </div>
    </StyledCreditPullRequest>
  );
};

const StyledCreditPullRequest = styled(ModalContent)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: calc(100vh - 140px);
  box-sizing: border-box;
  overflow-y: auto;
  max-height: 100%;
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .labeledInput {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .consent-container {
      display: flex;
      gap: 10px;
      align-items: center;
      .consent-checkbox-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        .consent-checkbox {
          width: 24px;
          height: 24px;
        }
      }
    }

    .location-container {
      display: flex;
      flex-direction: row;
      gap: 24px;
      width: 100%;

      .labeledInput {
        flex: 1;

        input,
        select {
          width: 100%;
        }
      }

      @media (max-width: ${theme.breakpoints[500]}) {
        flex-direction: column;
      }
    }
  }
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 12px;
`;

const StatBox = styled.div`
  text-align: center;
  border-radius: 8px;
  background: var(--Neutral-50, #f5f5f8);
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
`;

const StatValue = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const StatLabel = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 150%; /* 18px */
  color: ${theme.colors.gray[600]};
`;

const DebtList = styled.div`
  margin: 20px 0;
  flex: 1;
  /* todo dynamic height  - use flexbox to make it dynamic */
  max-height: 280px;
  overflow: auto;
  @media (max-height: ${(p) => p.theme.breakpoints[500]}) {
    max-height: 180px;
  }
`;

const DebtItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 2px dashed ${theme.colors.white[500]};
  @media (max-height: ${(p) => p.theme.breakpoints[500]}) {
    padding: 10px;
  }
`;

const DebtInfo = styled.div``;
const DebtBalance = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
`;

const DebtName = styled.div`
  font-weight: bold;
  font-size: 14px;
  font-weight: 600;
`;

const DebtType = styled.div`
  color: ${theme.colors.white[400]};
  font-size: 12px;
`;

export const getReadableDebtType = (str: string): string => {
  return str
    .replace("_", " ")
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

type TCreditReportDetailsScreenProps = {
  claims: MeridianLinkCreditReportClaim[];
  totalDebt: number;
  onSubmit: () => void;
  trackStepAction: (actionId: string, extras?: Record<string, any>) => void;
};
export const CreditReportDetailsScreen = (props: TCreditReportDetailsScreenProps) => {
  // TODO: refactor tracking
  useEffect(() => {
    const dischargeableClaims = getDischargeableClaims(props.claims);
    const totalDischargeableClaimsValue = dischargeableClaims.reduce((acc, claim) => acc + (claim.value || 0), 0) || 0;
    const totalDischargeableClaimsCount = dischargeableClaims.length;
    const debtTypes = Array.from(
      new Set(dischargeableClaims.map((claim) => claim.subType || claim.type || "type_missing"))
    ).join(", ");

    props.trackStepAction("success", {
      totalClaimsValue: props.totalDebt,
      totalClaimsCount: props.claims.length,
      totalDischargeableClaimsValue,
      totalDischargeableClaimsCount,
      debtTypes,
    });
  }, []);
  return (
    <ModalContent>
      <QuestionHeading>We successfully imported your debts!</QuestionHeading>
      <DetailDescriptionCentered>
        If you notice any errors or missing information, don't worry — everything will be finalized when you choose to
        enroll in a plan.
      </DetailDescriptionCentered>

      <StatsContainer>
        <StatBox>
          <StatValue>{props.claims.length}</StatValue>
          <StatLabel>ACCOUNTS</StatLabel>
        </StatBox>
        <StatBox>
          <StatValue>${props.totalDebt.toLocaleString()}</StatValue>
          <StatLabel>TOTAL DEBT</StatLabel>
        </StatBox>
      </StatsContainer>

      <DebtList>
        {props.claims.map((claim) => {
          const claimName = claim.creditor?.name || claim.subType || claim.type;

          return (
            <DebtItem key={claim.id}>
              <DebtInfo>
                <DebtName>{claimName}</DebtName>
                <DebtType>{getReadableDebtType(claim.subType || claim.type || "")}</DebtType>
              </DebtInfo>
              <DebtBalance>${(claim.value || "0").toLocaleString()}</DebtBalance>
            </DebtItem>
          );
        })}
      </DebtList>

      <Controls>
        <Button onClick={props.onSubmit} className="action-button" data-test-label="next-button">
          Next
        </Button>
      </Controls>
    </ModalContent>
  );
};
