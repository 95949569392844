import { useReducer } from "react";

type TState<TFormState, TStepName> = {
  formState: TFormState;
  stepName: TStepName;
};

export type TFlow<TFormState, TStepName> = (state: TFormState) => Array<{ showIf?: boolean; stepName: TStepName }>;

type TAction<TFormState> =
  | {
      type: "formStateUpdate";
      payload: Partial<TFormState>;
    }
  | {
      type: "goToNextStep";
    }
  | {
      type: "goToPreviousStep";
    }
  | {
      type: "restart";
      restartState: TFormState;
    };

const computeNextStep = <TFormState, TStepName>(
  flow: TFlow<TFormState, TStepName>,
  state: TState<TFormState, TStepName>
) => {
  const currentStep = state.stepName;
  const hydratedFlow = flow(state.formState);
  const currentStepIndex = hydratedFlow.findIndex((step) => {
    return step.stepName === currentStep;
  });

  for (let i = currentStepIndex + 1; i < hydratedFlow.length; i++) {
    const nextStep = hydratedFlow[i];

    if (nextStep.showIf === false) {
      continue;
    }

    return nextStep.stepName;
  }

  return null;
};

const computePreviousStep = <TFormState, TStepName>(
  flow: TFlow<TFormState, TStepName>,
  state: TState<TFormState, TStepName>
) => {
  const currentStep = state.stepName;
  const hydratedFlow = flow(state.formState);
  const currentStepIndex = hydratedFlow.findIndex((step) => {
    return step.stepName === currentStep;
  });

  for (let i = currentStepIndex - 1; i > 0; i--) {
    const previousStep = hydratedFlow[i];

    if (previousStep.showIf === false) {
      continue;
    }

    return previousStep.stepName;
  }

  return null;
};

const computeInitialStep = <TFormState, TStepName>(
  flow: TFlow<TFormState, TStepName>,
  initialFormState: TFormState
) => {
  const hydratedFlow = flow(initialFormState);
  return hydratedFlow[0].stepName;
};

export const useFlowManager = <TFormState, TStepName>(
  initialStep: TStepName,
  initialFormState: TFormState,
  flow: TFlow<TFormState, TStepName>
) => {
  type TConcreteState = TState<TFormState, TStepName>;
  type TConcreteAction = TAction<TFormState>;

  const initialState: TConcreteState = {
    formState: initialFormState,
    stepName: initialStep,
  };

  const reducer = (state: TConcreteState, action: TConcreteAction): TConcreteState => {
    if (action.type === "formStateUpdate") {
      return { ...state, formState: { ...state.formState, ...action.payload } };
    }

    if (action.type === "goToNextStep") {
      const nextStep = computeNextStep(flow, state);
      return { ...state, stepName: nextStep ?? state.stepName };
    }

    if (action.type === "goToPreviousStep") {
      const prevStep = computePreviousStep(flow, state);
      return { ...state, stepName: prevStep ?? state.stepName };
    }

    if (action.type === "restart") {
      return {
        ...state,
        stepName: computeInitialStep(flow, action.restartState),
        formState: action.restartState,
      };
    }

    return state;
  };

  const [state, dispatch] = useReducer<React.Reducer<TConcreteState, TConcreteAction>>(reducer, initialState);

  return { step: state.stepName, dispatch, formState: state.formState };
};
